<script setup></script>
<template>
  <div class="v-error">
    <div class="v-error-label">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.v-error {
  position: absolute;
  font-size: 13px;
  height: 26px;
  width: 90%;
  transform: translate3d(-6px, 35px, 0);
  overflow: hidden;
  pointer-events: none;
  bottom: 22px;
}

.v-error-label {
  padding: 0 0.35rem;
  color: rgb(255, 104, 28);
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  pointer-events: none;
  user-select: none;
  transform: translate3d(0px, 20px, 0);
  transition: transform 100ms ease-out, color 0.15s ease-out;
}
.v-error.active .v-error-label {
  transform: translate3d(0px, 3px, 0);
  transition: transform 100ms ease-out, color 0.15s ease-out;
  background: var(--background);
  border-radius: 10px;
}
</style>
